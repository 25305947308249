// npm
import React, { memo } from 'react'
import { GatsbyImage } from 'gatsby-plugin-image'
import { Box, Stack } from '@mui/material'

// components
import RoundFrame from '@atoms/media/RoundFrame'
import QuoteIcon from '@images/icons/quote.svg'

interface Props {
  d: Queries.DatoCmsQuote
}

const QuoteContent = ({ d }: Props) => {
  return (
    <>
      <Stack direction="row" alignItems="center" mb={4}>
        {d.image && (
          <RoundFrame mr={2}>
            <GatsbyImage
              image={d.image.gatsbyImageData}
              alt={`Image of ${d.quoteeName}`}
            />
          </RoundFrame>
        )}
        <Box>
          <Box color="white" typography="h5">
            {d.quoteeName}
          </Box>
          {d.jobTitle} at {d.client.name}
        </Box>
      </Stack>
      <Stack>
        <Box typography="quote">{d.quote}</Box>
        <Box alignSelf="flex-end">
          <QuoteIcon />
        </Box>
      </Stack>
      <Box typography="signature" mt={-4}>
        {d.quoteeName}
      </Box>
    </>
  )
}

export default memo(QuoteContent)
