// npm
import React, { memo } from 'react'
import { Box, BoxProps, styled } from '@mui/material'

interface Props extends BoxProps {
  children: React.ReactNode
}

const Frame = styled(Box)({
  img: {
    borderRadius: '50% !important',
  },
})

const RoundFrame = ({ children, ...props }: Props) => (
  <Frame {...props}>{children}</Frame>
)

export default memo(RoundFrame)
